import mnml from '@dryan-llc/mnml.js';
import { getSource, getSocialSource } from './lib/sources';
import { setCookie } from './lib/cookies';
import { scrollToElem } from './lib/scrolling';
import { flashMessage } from './lib/messages';
import { createDialog, dialogKeyboardListener } from './lib/dialogs';
import { track } from './lib/stats';
import { distanceBetweenPoints } from './lib/distance';

const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

if (getSource()) {
  setCookie('source', getSource(), { expires: 30 });
}

if (getSocialSource()) {
  setCookie('social_source', getSocialSource());
}

mnml.listen('load', 1, () => {
  if (document.documentElement.classList.contains('pledge-thanks')) {
    if ((window.scrollY || window.pageYOffset) > 0) {
      return;
    }
    const main = document.querySelector('main') as HTMLElement;
    if (main) {
      scrollToElem(main);
    }
  }
});

mnml.listen('cut', '[contenteditable][readonly]', (ev) => {
  if (ev.target && ev.target instanceof HTMLElement) {
    const value = (ev.target as HTMLElement).innerHTML;
    navigator?.clipboard?.writeText(value);
    requestAnimationFrame(() => {
      (ev.target as HTMLElement).innerHTML = value;
      if ((ev.target as HTMLElement).dataset.autoSelect) {
        (ev.target as HTMLElement).dispatchEvent(
          new Event('focusin', { bubbles: true })
        );
      }
    });
  }
});

mnml.listen('paste', '[contenteditable][readonly]', (ev) => {
  ev.preventDefault();
  return false;
});

mnml.listen('keydown', '[contenteditable][readonly]', (ev) => {
  const e = ev as KeyboardEvent;
  if (e.key === 'Esc' || e.key === 'Escape') {
    (e.target as HTMLElement)?.blur();
  }
  if (e.key === 'Tab') {
    return true;
  }
  if (!e.metaKey) {
    e.preventDefault();
    return false;
  }
});

mnml.listen(
  'focusin',
  '[contenteditable][data-auto-select="true"],.share-url__input',
  (ev) => {
    if (!ev.target) {
      return true;
    }
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(ev.target as Node);
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
);

mnml.listen(
  'focusout',
  '[contenteditable][data-auto-select="true"],.share-url__input',
  (ev) => {
    if (
      !ev.target ||
      !(ev.target as HTMLDivElement | HTMLInputElement).firstChild
    ) {
      return true;
    }
    const selection = window.getSelection();
    if (
      selection?.containsNode(
        (ev.target as HTMLDivElement | HTMLInputElement).firstChild as Node
      )
    ) {
      selection.removeAllRanges();
    }
  }
);

if (
  document.documentElement.classList.contains('static-page') ||
  document.documentElement.classList.contains('news-article')
) {
  [...document.querySelectorAll('.page__content p a:only-child')].map(
    (link) => {
      if (!link.previousSibling && !link.nextSibling) {
        link.classList.add('button', 'primary');
        link.closest('p')?.classList.add('text--center');
      }
    }
  );
}

mnml.listen('change', '.shopping-cart input', (ev) => {
  const input = ev.target as HTMLInputElement;
  const form = input.closest('form');
  if (!form) {
    return true;
  }
  const checkoutButton = form.querySelector(
    '[name="checkout"]'
  ) as HTMLButtonElement | null;
  checkoutButton?.setAttribute('disabled', '');
  checkoutButton?.classList.add('disabled');
  checkoutButton?.setAttribute(
    'title',
    'Please update cart before checking out.'
  );
});

const updatePledgeNamesCheckbox = document.querySelector(
  '.form__group--update-pledge-names input'
);

if (updatePledgeNamesCheckbox) {
  const nameFields: HTMLInputElement[] = [
    ...document.querySelectorAll(
      '.form__group--first-name input, .form__group--last-name input'
    ),
  ] as HTMLInputElement[];
  nameFields.map((input: HTMLInputElement) => {
    input.dataset.initial = input.value;
    return input;
  });
  if (!updatePledgeNamesCheckbox.matches(':checked')) {
    updatePledgeNamesCheckbox.closest('.form__group')?.classList.add('hidden');
  }
  mnml.listen(
    'change',
    '.form__group--first-name input, .form__group--last-name input',
    (ev) => {
      if (
        nameFields.filter((input) => input.dataset.initial !== input.value)
          .length
      ) {
        updatePledgeNamesCheckbox
          .closest('.form__group')
          ?.classList.remove('hidden');
      } else {
        updatePledgeNamesCheckbox
          .closest('.form__group')
          ?.classList.add('hidden');
      }
    }
  );
}

mnml.listen(
  'click',
  '.order-detail-link[data-modal-template]',
  (ev, anchor) => {
    const link = anchor as HTMLAnchorElement;
    ev.preventDefault();
    link.blur();
    const canEdit = link.dataset.canEdit === 'true';
    const template = document.querySelector(`#${link.dataset.modalTemplate}`);
    if (!template) {
      return true;
    }
    const stripeType = link.dataset.stripeId?.startsWith('in_')
      ? 'invoices'
      : 'payments';
    const stripeLink = `<a href="https://dashboard.stripe.com/${stripeType}/${link.dataset.stripeId}" target="_blank" rel="noopener noreferrer" class="button" data-color="blue">View on Stripe</a>`;
    const printLink = `<a href="#print" class="button print-button" data-color="primary" data-print-url="${link.dataset.printUrl}">Print Label</a>`;
    const dialog = createDialog(template.innerHTML, {
      buttons: [
        link.dataset.stripeId && canEdit ? stripeLink : null,
        link.dataset.printUrl && canEdit ? printLink : null,
        canEdit
          ? `<a class="button" data-color="primary" href="${link.href}#update-order">Update Order</a>`
          : null,
      ].filter((btn) => !!btn) as string[],
      uid: link.dataset.uid,
    });
    const prevLink = link
      .closest('tr')
      ?.previousElementSibling?.querySelector(
        '.order-detail-link[data-modal-template]'
      ) as HTMLAnchorElement | null;
    const nextLink = link
      .closest('tr')
      ?.nextElementSibling?.querySelector(
        '.order-detail-link[data-modal-template]'
      ) as HTMLAnchorElement | null;
    if (prevLink) {
      dialog.dataset.previousUid = prevLink.dataset.uid;
    }
    if (nextLink) {
      dialog.dataset.nextUid = nextLink.dataset.uid;
    }
    dialog.addEventListener('close', () => {
      window.removeEventListener('keydown', dialogKeyboardListener);
    });
    window.addEventListener('keydown', dialogKeyboardListener);
    dialog.showModal();
  }
);

mnml.listen('click', 'dialog', (ev, elem) => {
  const event = ev as MouseEvent;
  const dialog = elem as HTMLDialogElement;
  const rect = dialog.getBoundingClientRect();
  if (
    !(
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width
    )
  ) {
    dialog.close();
  }
});

mnml.listen('click', '.modal__close', (ev, button) => {
  ev.preventDefault();
  button.closest('dialog')?.close();
});

const sortableGrids = [
  ...document.querySelectorAll('.card-grid[data-coords]'),
] as HTMLElement[];

const sortGrid = (grid: HTMLElement) => {
  if (!grid.dataset.coords) {
    return;
  }
  const coords = JSON.parse(grid.dataset.coords);
  const cards = [...grid.querySelectorAll('pr-card')] as HTMLElement[];
  cards
    .filter((card: HTMLElement) => {
      try {
        const cardCoords = JSON.parse(
          card.getAttribute('coords') || card.dataset.coords || '{}'
        );
        const distance = distanceBetweenPoints(coords, cardCoords);
        if (distance === null) {
          return false;
        }
        card.dataset.distance = `${distance.toFixed(2)} km`;
        return distance <= 50;
      } catch (e) {}
      return false;
    })
    .forEach((nearbyCard: HTMLElement) => {
      nearbyCard.classList.add('nearby');
      const figure = nearbyCard.querySelector('.image');
      if (figure) {
        figure.prepend(mnml.html`<span class="promo-banner">Near you!</span>`);
      }
    });
  // sort cards into three groups: nearby, current, and expired
  // current and expired are determined by data-ends attribute compared to current date
  const nearbyCards = cards.filter((card) => card.classList.contains('nearby'));
  const currentCards = cards.filter(
    (card) =>
      !card.classList.contains('nearby') &&
      card.dataset.ends &&
      new Date(card.dataset.ends) > new Date()
  );
  const expiredCards = cards.filter(
    (card) => !nearbyCards.includes(card) && !currentCards.includes(card)
  );
  // randomize each group
  nearbyCards.sort(() => Math.random() - 0.5);
  currentCards.sort(() => Math.random() - 0.5);
  expiredCards.sort(() => Math.random() - 0.5);
  // re-insert cards into grid
  grid.replaceChildren(...nearbyCards, ...currentCards, ...expiredCards);
};

sortableGrids.forEach((grid) => {
  sortGrid(grid);
});

if (document.querySelector('#bulk-order-form')) {
  mnml.listen('change', '#bulk-order-form #id_local_delivery', (ev, input) => {
    const form = input.closest('form');
    if (!form) {
      return;
    }
    const addressRows = [
      ...form.querySelectorAll(
        '.form__group--street-address, .form__group--extended-address, .form__group--locality, .form__group--region, .form__group--postal-code, .form__group--country'
      ),
    ];
    addressRows.map((row) => {
      if ((input as HTMLInputElement).checked) {
        row.classList.add('hidden');
      } else {
        row.classList.remove('hidden');
      }
    });
  });
}

mnml.listen('click', '.print-button[data-print-url]', (ev, button) => {
  ev.preventDefault();

  if (!button.dataset.printUrl) {
    return;
  }

  function setPrint(this: HTMLIFrameElement) {
    if (!this || !this.contentWindow) {
      return;
    }

    const container = this;

    function closePrint() {
      document.body.removeChild(container);
    }

    this.contentWindow.onbeforeunload = closePrint;
    this.contentWindow.onafterprint = closePrint;
    this.contentWindow.focus();
    this.contentWindow.print();
  }

  const iframe = document.createElement('iframe');
  iframe.classList.add('print-iframe');
  iframe.addEventListener('load', setPrint);
  iframe.src = button.dataset.printUrl;
  document.body.append(iframe);
});

navigator.serviceWorker?.addEventListener('message', (ev) => {
  const data = ev.data;
  const { type, payload } = data;
  switch (type) {
    case 'changeHash':
      window.location.hash = payload.hash;
      break;
  }
});

if (
  localStorage.getItem('pledges') &&
  !document.body.dataset.user &&
  !location.pathname.startsWith('/login/')
) {
  (async () => {
    // if the user isn't logged in, see if any pledges are ready to be fulfilled
    const pledges = JSON.parse(localStorage.getItem('pledges') || '[]') as {
      [key: string]: string;
    };
    let found = false;
    await Promise.all(
      Object.entries(pledges).map(async ([pledgeId, campaignId]) => {
        if (found) return true;
        return fetch(`/api/campaigns/${campaignId}/`)
          .then((res) => res.json())
          .then((campaign) => {
            if (campaign.expired) {
              const campaignEnds = new Date(campaign.end_localized);
              const now = new Date();
              // if campaign has ended in the last 2 weeks, return true
              if (campaignEnds.getTime() + 12096e5 > now.getTime()) {
                found = true;
                return true;
              } else {
                delete pledges[pledgeId];
                localStorage.setItem('pledges', JSON.stringify(pledges));
              }
            }
            return false;
          });
      })
    );
    if (found) {
      flashMessage(
        `<a href="/login/">Log in</a> to fulfill your pledge!`,
        'info'
      );
    }
  })();
}

(() => {
  const updateClearableFileInputButtons = () => {
    (
      [
        ...document.querySelectorAll('.clearable-file-input'),
      ] as HTMLDivElement[]
    ).map((wrapper) => {
      const input = wrapper.querySelector(
        'input[type="file"]'
      ) as HTMLInputElement | null;
      const button = wrapper.querySelector(
        'label.button'
      ) as HTMLLabelElement | null;
      if (!input || !button) {
        return;
      }
      if (input.readOnly) {
        button.setAttribute('hidden', '');
      } else {
        button.removeAttribute('hidden');
      }
      if (input.disabled) {
        button.setAttribute('disabled', '');
      } else {
        button.removeAttribute('disabled');
      }
    });
  };
  const observer = new MutationObserver(updateClearableFileInputButtons);
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
  updateClearableFileInputButtons();
})();

track(
  'theme',
  darkModeQuery.matches && document.documentElement.dataset.theme !== 'light'
    ? 'dark'
    : 'light'
);

const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  return (event.returnValue = '');
};

mnml.listen('change', 'form', (event) => {
  if (
    (event.target as Element)?.closest?.(
      '.filters, [data-ignore-unsaved-changes="true"]'
    )
  ) {
    return;
  }
  window.addEventListener('beforeunload', beforeUnloadHandler, {
    capture: true,
  });
});

mnml.listen('submit', 'form', () => {
  window.removeEventListener('beforeunload', beforeUnloadHandler, {
    capture: true,
  });
});

const relativeTimeFormatter = new Intl.RelativeTimeFormat(
  navigator.language || 'en',
  {
    localeMatcher: 'best fit',
    numeric: 'auto',
  }
);

const dateFormatter = new Intl.DateTimeFormat(navigator.language || 'en', {
  dateStyle: 'medium',
  timeStyle: 'short',
});

const toRelativeTime = (time: HTMLTimeElement) => {
  const date = new Date(time.getAttribute('datetime') as string);
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const seconds = Math.round(diff / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  if (days) {
    time.textContent = relativeTimeFormatter.format(days, 'day');
    setTimeout(() => {
      toRelativeTime(time);
    }, 86400000);
  } else if (hours) {
    time.textContent = relativeTimeFormatter.format(hours, 'hour');
    setTimeout(() => {
      toRelativeTime(time);
    }, 3600000);
  } else if (minutes) {
    time.textContent = relativeTimeFormatter.format(minutes, 'minute');
    setTimeout(() => {
      toRelativeTime(time);
    }, 60000);
  } else {
    time.textContent = 'moments ago';
    setTimeout(() => {
      toRelativeTime(time);
    }, 10000);
  }
};

const toLocalTime = (time: HTMLTimeElement) => {
  const date = new Date(time.getAttribute('datetime') as string);
  time.textContent = dateFormatter.format(date);
  time.removeAttribute('data-convert');
  time.setAttribute('data-converted', '');
};

new MutationObserver((entries) => {
  entries.forEach((entry) => {
    (entry.target as Element)
      ?.querySelectorAll('time[data-convert="relative"][datetime]')
      .forEach((time) => {
        toRelativeTime(time as HTMLTimeElement);
      });
    (entry.target as Element)
      ?.querySelectorAll('time[data-convert="local"][datetime]')
      .forEach((time) => {
        toLocalTime(time as HTMLTimeElement);
      });
  });
}).observe(document.body, {
  childList: true,
  subtree: true,
});

document
  .querySelectorAll('time[data-convert="relative"][datetime]')
  .forEach((time) => toRelativeTime(time as HTMLTimeElement));
document
  .querySelectorAll('time[data-convert="local"][datetime]')
  .forEach((time) => toLocalTime(time as HTMLTimeElement));
